import { type LoaderFunctionArgs, json } from "@remix-run/node";
import { getFrenchAddresses } from "app/server/geoloc-address.server";
import { cacheHeaders } from "app/server/utils.server";
import ProviderTooltip from "components/Interface/App/AppTooltip.tsx";
import {
  ErrorList,
  Field,
  type ListOfErrors,
} from "components/Interface/Forms/forms.tsx";
import { LoadingSpinner } from "components/Interface/LoadingSpinner.tsx";
import { Label } from "components/UI/Label";
import { LoadingButton } from "components/shadcn-ui/button";
import {
  useAddressCombobox,
  useAddressComboboxControlled,
} from "hooks/useAddressCombobox.tsx";
import { useGetUserPosition } from "hooks/useGetUserPosition.tsx";
import { useOptionalUser } from "hooks/useUser";
import { CursorPointer } from "iconoir-react";
import type React from "react";
import { useId } from "react";
import { cn } from "utils/utils";

export const loader = async ({ request, context }: LoaderFunctionArgs) => {
  return json(
    { addresses: await getFrenchAddresses({ request, context }) },
    { headers: cacheHeaders },
  );
};

export type AddressComboboxType = {
  addresses: Awaited<ReturnType<typeof getFrenchAddresses>>;
};

type UserAddressType =
  | "businessAddress"
  | "billingAddress"
  | "address"
  | "leadAddress";

export function AddressCombobox({
  placeIdInputName,
  defaultAddressValue = "",
  defaultPlaceIdValue = "",
  icon,

  inputProps,
  labelProps,
  errors,
  onAddressChange,
  inputName,
}: {
  defaultAddressValue?: string;
  defaultPlaceIdValue?: string;
  theme?: "default" | "lead";
  // scale?: "normal" | "large";
  icon?: React.ReactNode;

  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
  inputProps: React.InputHTMLAttributes<HTMLInputElement>;
  errors?: ListOfErrors;
  inputName: string; // <= Let conform handle this
  placeIdInputName: string; // <= Let conform handle this

  onAddressChange?: ({
    address,
    placeId,
  }: {
    address: string;
    placeId: string;
  }) => void;
}) {
  const { getUserPosition, latLng } = useGetUserPosition({
    successFn: ({ lat, lng }) => {
      addressFetcher.submit(
        {
          latitude: lat.toString(),
          longitude: lng.toString(),
        },
        {
          method: "get",
          action: "/resources/get-address-by-lat-lng",
        },
      );
      cb.openMenu();
    },
  });

  const user = useOptionalUser();
  const triggerGetAddressByLatLng = () => {
    if (!user?.authOptions.isAdmin) {
      return;
    }
    if (
      !verifiedAddress ||
      !verifiedAddress.latitude ||
      !verifiedAddress.longitude
    ) {
      return;
    }
    // Only admin can get address by lat lng
    addressFetcher.submit(
      {
        latitude: verifiedAddress.latitude.toString(),
        longitude: verifiedAddress.longitude.toString(),
      },
      {
        method: "get",
        action: "/resources/get-address-by-lat-lng",
      },
    );
    cb.openMenu();
  };

  const {
    selectedAddress,
    showSpinner,
    addressFetcher,
    cb,
    displayMenu,
    addresses,
    verifiedAddress,
    isVerificationLoading,
  } = useAddressCombobox({
    defaultAddress: defaultAddressValue,
    defaultPlaceId: defaultPlaceIdValue,
    latLngLoading: latLng.loading,
    onAddressChange,
    enableVerification: user?.authOptions.isAdmin,
  });

  const fallbackId = useId();
  const id = inputProps.id ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;

  const { value, className, ...visibleInputProps } = inputProps;

  const isDisabled = inputProps?.disabled || inputProps?.readOnly;
  // const isDefaultTheme = theme === "default";
  // const isLeadTheme = theme === "lead";
  const SearchIcon = () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.41345 8.74448C0.818107 8.51296 0.520434 8.39719 0.433528 8.23039C0.358191 8.08579 0.358089 7.91354 0.433257 7.76886C0.519967 7.60195 0.817504 7.48584 1.41258 7.25361L18.3003 0.663274C18.8375 0.453643 19.1061 0.348827 19.2777 0.406163C19.4268 0.455955 19.5437 0.572923 19.5935 0.721972C19.6509 0.8936 19.5461 1.16219 19.3364 1.69937L12.7461 18.5871C12.5139 19.1822 12.3977 19.4797 12.2308 19.5664C12.0862 19.6416 11.9139 19.6415 11.7693 19.5662C11.6025 19.4793 11.4867 19.1816 11.2552 18.5862L8.6271 11.8282C8.58011 11.7074 8.55661 11.647 8.52031 11.5961C8.48815 11.551 8.44871 11.5115 8.40361 11.4794C8.35273 11.4431 8.29231 11.4196 8.17146 11.3726L1.41345 8.74448Z"
        fill="#14B480"
      />
    </svg>
  );

  return (
    <div className="relative w-full">
      <input
        name={placeIdInputName}
        type="hidden"
        value={selectedAddress?.id ?? defaultPlaceIdValue}
      />

      <div className="relative flex w-full flex-row items-center gap-2">
        {/* {icon ? (
          <Label
            htmlFor={id}
            {...labelProps}
            className="text-2xl text-midnightblue"
          >
            {icon}
          </Label>
        ) : null} */}

        <Field
          labelProps={{
            htmlFor: id,
            ...labelProps,
          }}
          className="w-full grow"
          inputProps={{
            ...cb.getInputProps({}),
            className: cn(
              // {
              //   "":
              //     isDefaultTheme,
              //   "m-[2px] mx-[-2px] focus:border-[#0196ED]/[.33]":
              //     isLeadTheme,
              //   "px-4 py-3": scale === "normal",
              //   "px-5 py-6": scale === "large",
              // },
              className,
            ),
            id: id,
            name: inputName,
            "aria-invalid": errorId ? true : undefined,
            "aria-describedby": errorId,
            ...visibleInputProps,
            ...(defaultAddressValue && {
              autoComplete: "off",
            }),
          }}
          icon={icon}
        />

        {!showSpinner && !isDisabled && latLng.canGeoLocate ? (
          <ProviderTooltip
            className="absolute  cursor-pointer bottom-0 h-12 right-3"
            content={"Trouvez votre adresse"}
            triggerComponent={
              <button
                disabled={isDisabled}
                type="button"
                aria-label="Détecter l'adresse"
                className="read-only:text-gray-600 disabled:text-gray-600 indeterminate:absolute disabled:cursor-pointer h-12 "
                onClick={() => {
                  getUserPosition();
                }}
              >
                <SearchIcon aria-hidden="true" />
                {/* <CursorPointer className="text-inherit" aria-hidden="true" /> */}
              </button>
            }
          />
        ) : null}
        <LoadingSpinner
          className="absolute right-0 my-auto mr-2 translate-y-1/2"
          aria-hidden="true"
          loading={showSpinner}
        />
      </div>
      <AddressesDropdown
        addresses={addresses}
        cb={cb}
        displayMenu={displayMenu}
      />
      {verifiedAddress && !verifiedAddress.postcode ? (
        <div className="flex flex-col gap-2 pt-2">
          <ErrorList
            errors={[
              `L'adresse ${verifiedAddress.fullAddress} a été trouvée, mais elle ne possède pas de code postal.`,
            ]}
          />
          <LoadingButton
            type="button"
            isLoading={isVerificationLoading}
            variant="midnightblue"
            size="sm"
            className="w-fit"
            onClick={() => triggerGetAddressByLatLng()}
          >
            Voir les adresses aux alentours
          </LoadingButton>
        </div>
      ) : null}

      {errors ? (
        <div className="min-h-[32px] px-2 pb-3 pt-0">
          {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
        </div>
      ) : null}
    </div>
  );
}

export function AddressComboboxControlled({
  name,
  addressValue,
  placeIdValue,
  scale = "normal",
  icon,

  inputProps,
  labelProps,
  errors,
  onAddressChange,
}: {
  name: UserAddressType;
  addressValue: string;
  placeIdValue: string;
  scale?: "normal" | "large";
  icon?: React.ReactNode;

  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
  inputProps: React.InputHTMLAttributes<HTMLInputElement>;
  errors?: ListOfErrors;
  onAddressChange: ({
    address,
    placeId,
  }: {
    address: string;
    placeId: string;
  }) => void;
}) {
  const { getUserPosition, latLng } = useGetUserPosition({
    successFn: ({ lat, lng }) => {
      addressFetcher.submit(
        {
          latitude: lat.toString(),
          longitude: lng.toString(),
        },
        {
          method: "get",
          action: "/resources/get-address-by-lat-lng",
        },
      );
      cb.openMenu();
    },
  });

  const {
    selectedAddress,
    showSpinner,
    addressFetcher,
    cb,
    displayMenu,
    addresses,
  } = useAddressComboboxControlled({
    value: addressValue,
    placeId: placeIdValue,
    latLngLoading: latLng.loading,
    onAddressChange,
  });

  const fallbackId = useId();
  const id = inputProps.id ?? fallbackId;
  const errorId = errors?.length ? `${id}-error` : undefined;

  const { value, ...visibleInputProps } = inputProps;

  const isDisabled = inputProps?.disabled || inputProps?.readOnly;
  return (
    <div className="relative">
      {name === "businessAddress" ? (
        <input
          name="businessPlaceId"
          type="hidden"
          value={selectedAddress?.id}
        />
      ) : null}
      {name === "billingAddress" ? (
        <input
          name="billingPlaceId"
          type="hidden"
          value={selectedAddress?.id}
        />
      ) : null}
      {name === "address" ? (
        <input name="placeId" type="hidden" value={selectedAddress?.id} />
      ) : null}

      <Label htmlFor={id} {...labelProps} className="text-start" />
      <div className="flex w-full shrink-0 grow basis-[200px] flex-row items-center gap-2">
        {icon ? (
          <Label
            htmlFor={id}
            {...labelProps}
            className="text-midnightblue text-2xl"
          >
            {icon}
          </Label>
        ) : null}
        <input
          {...cb.getInputProps({
            className: `${
              scale === "normal" ? "px-4 py-3" : "px-5 py-6"
            } block disabled:text-gray-600 read-only:text-gray-600 bg-light-iron placeholder:text-gray-600 text-midnightblue border-2 border-iron w-full appearance-none rounded-md focus:border-gray-600 focus:outline-none focus:ring-0 text-sm`,
          })}
          id={id}
          aria-invalid={errorId ? true : undefined}
          aria-describedby={errorId}
          {...visibleInputProps}
          {...(addressValue && {
            autoComplete: "off",
          })}
        />
        {!showSpinner && !isDisabled && latLng.canGeoLocate ? (
          <ProviderTooltip
            className="absolute right-0 my-auto ml-auto mr-2 size-5 cursor-pointer"
            content={"Trouvez votre adresse"}
            triggerComponent={
              <button
                disabled={isDisabled}
                type="button"
                aria-label="Détecter l'adresse"
                className="read-only:text-dark-iron disabled:text-dark-iron indeterminate:absolute disabled:cursor-pointer"
                onClick={() => {
                  getUserPosition();
                }}
              >
                <CursorPointer className="text-inherit" aria-hidden="true" />
              </button>
            }
          />
        ) : null}
        <LoadingSpinner
          className="absolute right-0 my-auto mr-2"
          aria-hidden="true"
          loading={showSpinner}
        />
      </div>
      <AddressesDropdown
        addresses={addresses}
        cb={cb}
        displayMenu={displayMenu}
      />

      {errors ? (
        <div className="min-h-[32px] px-2 pb-3 pt-0">
          {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
        </div>
      ) : null}
    </div>
  );
}

const AddressesDropdown = ({
  addresses,
  displayMenu,
  cb,
}: {
  displayMenu: boolean;
  addresses: AddressComboboxType["addresses"];
  cb: ReturnType<typeof useAddressCombobox>["cb"];
}) => {
  return (
    <ul
      {...cb.getMenuProps({
        className: `p-0 m-0 border-none flex flex-col items-start absolute z-10 bg-white shadow-lg w-full rounded-b-soft max-h-[180px] overflow-y-scroll ${
          !displayMenu ? "hidden" : ""
        }`,
      })}
    >
      {displayMenu
        ? addresses.map((address, index) => (
            <li
              key={address.id}
              data-testid={`address-${index}`}
              {...cb.getItemProps({
                item: address,
                index,
              })}
              className={cn(
                `text-midnightblue flex w-full grow cursor-pointer flex-col rounded-none border-b border-b-gray-300 bg-transparent p-3 px-2 py-1 text-base font-normal text-gray-500 hover:bg-transparent hover:text-green-500 active:bg-gray-100 active:text-gray-800 ${cb.highlightedIndex === index ? "bg-slate-100" : ""}`,
              )}
            >
              {address.address}
            </li>
          ))
        : null}
    </ul>
  );
};
