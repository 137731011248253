import { useFetcher, useSearchParams } from "@remix-run/react";
import { useCombobox } from "downshift";
import { useEffect, useId, useState } from "react";
import { flushSync } from "react-dom";
import type { AddressComboboxType } from "routes/resources+/get-address";
import type { loader as feedbackLoader } from "routes/resources+/get-address-by-place-id";
import { useSpinDelay } from "spin-delay";

export const useAddressCombobox = ({
  defaultAddress = null,
  defaultPlaceId = null,
  latLngLoading = false,
  onAddressChange,
  enableVerification = false,
}: {
  defaultAddress?: string | null;
  defaultPlaceId?: string | null;
  enableVerification?: boolean;
  latLngLoading?: boolean;
  onAddressChange?: ({
    address,
    placeId,
  }: {
    address: string;
    placeId: string;
  }) => void;
}) => {
  const addressFetcher = useFetcher<AddressComboboxType>();
  const addressAvailabilityFetcher = useFetcher<typeof feedbackLoader>();

  const id = useId();
  const addresses = addressFetcher.data?.addresses ?? [];
  type AddressType = (typeof addresses)[number];
  const [selectedAddress, setSelectedAddress] = useState<
    null | undefined | AddressType
  >({
    address: defaultAddress ?? "",
    id: defaultPlaceId ?? "",
  });
  // An errored address is an address that was suggested by Google, but that is not associated with a zipcode.
  const [searchParams] = useSearchParams();

  const cb = useCombobox<AddressType>({
    id,

    onSelectedItemChange: ({ selectedItem }) => {
      setSelectedAddress(selectedItem);
      onAddressChange?.({
        address: selectedItem?.address ?? "",
        placeId: selectedItem?.id ?? "",
      });
      // n Lat & Lon
    },
    items: addresses,
    itemToString: (item) => (item ? item.address : ""),
    defaultInputValue:
      defaultAddress ??
      searchParams.get("address") ??
      searchParams.get("search") ??
      "",
    onInputValueChange: (changes) => {
      addressFetcher.submit(
        { search: changes.inputValue ?? "" },
        { method: "get", action: "/resources/get-address" },
      );
    },
  });

  const busy = addressFetcher.state !== "idle" || latLngLoading;
  const showSpinner = useSpinDelay(busy, {
    delay: 150,
    minDuration: 500,
  });
  const displayMenu = cb.isOpen && addresses.length > 0;

  useEffect(() => {
    if (!enableVerification) {
      return;
    }
    if (!selectedAddress || !selectedAddress.id || !selectedAddress.address) {
      return;
    }
    addressAvailabilityFetcher.submit(
      {
        placeId: selectedAddress.id,
      },
      {
        method: "GET",
        action: "/resources/get-address-by-place-id",
      },
    );
  }, [selectedAddress]);

  return {
    displayMenu,
    showSpinner,
    selectedAddress,
    cb,
    addressFetcher,
    addresses,
    verifiedAddress: addressAvailabilityFetcher.data,
    isVerificationLoading: addressAvailabilityFetcher.state !== "idle",
  };
};

export const useAddressComboboxControlled = ({
  value,
  placeId,
  latLngLoading = false,
  onAddressChange,
}: {
  value: string;
  placeId: string;
  latLngLoading?: boolean;
  onAddressChange: ({
    address,
    placeId,
  }: {
    address: string;
    placeId: string;
  }) => void;
}) => {
  const addressFetcher = useFetcher<AddressComboboxType>();
  const id = useId();
  const addresses = addressFetcher.data?.addresses ?? [];
  type AddressType = (typeof addresses)[number];
  const [selectedAddress, setSelectedAddress] = useState<
    null | undefined | AddressType
  >({
    address: value ?? "",
    id: placeId ?? "",
  });

  const cb = useCombobox<AddressType>({
    id,

    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        setSelectedAddress(selectedItem);
        flushSync(() => {
          onAddressChange({
            address: selectedItem?.address,
            placeId: selectedItem?.id,
          });
        });
      }
    },
    items: addresses,
    itemToString: (item) => (item ? item.address : ""),
    inputValue: value,
    onInputValueChange: ({ inputValue, selectedItem }) => {
      if (selectedItem) return;
      addressFetcher.submit(
        { search: inputValue ?? "" },
        { method: "get", action: "/resources/get-address" },
      );
      onAddressChange({
        address: inputValue ?? "",
        placeId: placeId,
      });
    },
  });

  const busy = addressFetcher.state !== "idle" || latLngLoading;
  const showSpinner = useSpinDelay(busy, {
    delay: 150,
    minDuration: 500,
  });
  const displayMenu = cb.isOpen && addresses.length > 0;

  return {
    displayMenu,
    showSpinner,
    selectedAddress,
    cb,
    addressFetcher,
    addresses,
  };
};
