import { useState } from "react";
import { useToast } from "components/shadcn-ui/use-toast.ts";

export const useGetUserPosition = ({
  successFn = () => {},
}: {
  successFn?: ({ lat, lng }: { lat: number; lng: number }) => void;
}) => {
  const [latLng, setLatLng] = useState<{
    loading: boolean;
    latitude: number | null;
    longitude: number | null;
    canGeoLocate: boolean;
  }>({
    loading: false,
    latitude: null,
    longitude: null,
    canGeoLocate: true,
  });

  const { toast } = useToast();

  const getUserPosition = () => {
    if (!navigator.geolocation) {
      setLatLng((oldValues) => ({
        ...oldValues,
        canGeoLocate: false,
      }));
      return;
    }
    setLatLng((oldValues) => ({ ...oldValues, loading: true }));

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;

        setLatLng((oldValues) => ({
          ...oldValues,
          latitude,
          longitude,
          loading: false,
        }));

        successFn?.({ lat: latitude, lng: longitude });
      },
      (error) => {
        if (error.PERMISSION_DENIED) {
          toast({
            description:
              "Votre navigateur n'a pas les permissions pour vous localiser. Vous pouvez l'activer dans les paramètres de votre navigateur.",
            variant: "error",
          });
        } else if (error.TIMEOUT) {
          toast({
            description:
              "La géolocalisation a pris trop de temps. Veuillez réessayer.",
            variant: "error",
          });
        } else if (error.POSITION_UNAVAILABLE) {
          toast({
            description:
              "Votre position n'a pas pu être déterminée. Veuillez réessayer.",
            variant: "error",
          });
        } else {
          toast({
            description: "Une erreur est survenue. Veuillez réessayer.",
            variant: "error",
          });
        }
        setLatLng((oldValues) => ({
          ...oldValues,
          loading: false,
        }));
      },
      {
        timeout: 10_000,
        enableHighAccuracy: true,
        maximumAge: 60_000, // 60 seconds
      },
    );
  };
  return { getUserPosition, latLng };
};
